import React, { useState } from "react";
import "./Modal.css";
import { Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { sendEmailAPI } from "../../Config/Config";
import closeIcon from "../../../src/IMG/icon/VectorCloseModel.png";
import Spinner from 'react-bootstrap/Spinner';


const SubscribeModal = (props) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [Number, setNumber] = useState("");
  const [describedAs, setDescribedAs] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [interest, setInterest] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [validationerrorMessage, setValidationErrorMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [loading,setLoading] = useState(false);
  const interestArray = [
    "Dance",
    "Music",
    "Creative",
    "Theatre",
    "Sports",
    "Knowledge",
  ];
  function Dataclearuserinput(allClear = false) {
    setFullName("")
    setEmail("")
    setNumber("")
    setInterest([])
    setTermsAccepted(false)
    setDescribedAs("")
    setErrorMessage("")
    setValidationErrorMessage("")
    if(allClear) {
      setSuccess("");
    }
  }
  const changeInterest = (value) => {
    if (interest.includes(value)) {
      setInterest(interest.filter((ele) => ele != value));
    } else {
      setInterest([...interest, value]);
    }
  };

  const submitForm = () => {
    var validationErrorDiv = document.getElementById("subscriptionModal");
    var isValid = true;
    setErrorMessage("");
    setValidationErrorMessage("");
    setSuccess("");
    if (describedAs == "") {
      isValid = false;
    }
    if (fullName == "") {
      isValid = false;
    }
    if (Number == "") {
      isValid = false;
    }
    if (email == "") {
      isValid = false;
    }
  
    if (!interest.length) {
      isValid = false;
    } else if (!termsAccepted) {
      isValid = false;
    }

    if (!isValid) {
      setValidationErrorMessage("All the fields are mandatory");
      validationErrorDiv.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      return false;
    }

    var validMobile = /^[0-9]{10}$/;
    if(!Number.match(validMobile)){
      setValidationErrorMessage("Enter 10 digit mobile number");
      validationErrorDiv.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      return false;
    }

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email.match(validRegex)) {
      setValidationErrorMessage("Please enter a valid email");
      validationErrorDiv.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      return false;
    }
    
    setLoading(true);
    const emailData = {
      describedAs: describedAs,
      featured: interest,
      username: fullName,
      email: email,
      phone: Number,
    };
    axios
      .post(sendEmailAPI, emailData)
      .then((response) => {
        setLoading(true);
        var APIMessageDiv = document.getElementById("APIMessageDiv");
        if (response?.data?.success) {
          setLoading(false);
          setSuccess("Thank you for submitting the form.");
          Dataclearuserinput()
        } else {
          setLoading(false);
          setErrorMessage("Something went wrong. Please try again");
        }
        APIMessageDiv.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage("Something went wrong. Please try again");
        var APIMessageDiv = document.getElementById("APIMessageDiv");
        APIMessageDiv.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      });
  };

  const handleClose = () => {
    Dataclearuserinput(true)
    props.handleClose();
  };

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose}>
        <Modal.Body>
          <div className="mainform p-lg-5 p-md-4 p-4 ">
            <h3 className="closemenuicon my-2" onClick={handleClose}>
              <img src={closeIcon} height={15} width={15} alt="modelClose" />
            </h3>
            <h3 id="subscriptionModal" className="heading col-12" style={{ border: "0px solid red" }}>
            SUBSCRIBE TO TALENTVERSE
            </h3>
            <p className="text-danger">{validationerrorMessage}</p>
            <p className="titlename">What best describes you?</p>
            <div
              key="gender-radio"
              className="d-flex mb-3 justify-content-space-between"
              style={{ border: "0px solid red" }}
            >
              <div
                className="d-flex align-items-center"
                style={{ border: "0px solid red" }}
              >
                <Form.Check
                  type="radio"
                  // label="Artist/Creator"
                  name="Artist/Creator"
                  value="Artist/Creator"
                  checked={describedAs === "Artist/Creator"}
                  onChange={(e) => setDescribedAs(e.target.value)}
                  className="customRadioBtn"
                />
                <Form.Label className="my-0 mx-2">
                  <p className="my-0 radparatitle">Artist/Creator</p>
                </Form.Label>
              </div>
              <div className="d-flex align-items-center mx-1">
                <Form.Check
                  type="radio"
                  // label="General User/Lerner"
                  name="General User/Learner"
                  value="General User/Learner"
                  checked={describedAs === "General User/Learner"}
                  onChange={(e) => setDescribedAs(e.target.value)}
                  className="customRadioBtn "
                />
                <Form.Label className="my-0 mx-2">
                  <p className="my-0 radparatitle">General User/Learner</p>
                </Form.Label>
              </div>
            </div>
            <Form.Group controlId="fullName" className="mb-3 form-group-width ">
              <Form.Label className="mx-2">
                <b className="titlename">Full Name</b>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className="inputbakcolor"
              />
            </Form.Group>

            <Form.Group controlId="Number" className="mb-3 form-group-width">
              <Form.Label className="mx-2">
                <b className="titlename">Phone Number</b>
              </Form.Label>
              <Form.Control
                type="Number"
                placeholder="Enter your Phone Number"
                value={Number}
                onChange={(e) => setNumber(e.target.value)}
                className="inputbakcolor"
              />
            </Form.Group>

            <Form.Group controlId="email" className="mb-3 form-group-width">
              <Form.Label className="mx-2">
                <b className="titlename">Email</b>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="inputbakcolor"
              />
               <Form.Label className="mx-2">
              </Form.Label>
            </Form.Group>

            <Form.Group controlId="">
              <Form.Label className="titlename mx-2">
                <b>Choose interest</b>
              </Form.Label>
              <div className="row mb-3 mx-0">
                {interestArray.map((ele, index) => {
                  return (
                    <div
                      className="col-lg-3 col-md-3 col-xl-3 col-4 px-lg-1 px-1 py-md-2 py-1"
                      style={{ border: "0px solid red" }}
                    >
                      <div
                        className="col-12"
                        style={{ border: "0px solid red" }}
                      >
                        <div class={`cat action m-0 width_${index}`}>
                          <label>
                            <input
                              type="checkbox"
                              value={ele}
                              checked={interest.includes(ele)}
                              onClick={() => changeInterest(ele)}
                            />
                            <span>{ele}</span>
                          </label>
                          
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Form.Group>

            <Form.Group
              controlId="termsAccepted"
              className="mb-2 mx-2 d-flex align-items-center"
            >
              <Form.Check
                type="checkbox"
                // label="Lorem ipsum is simply dummy text of  the T&C and PP industry.Lorem ipsum has been"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(!termsAccepted)}
              />
              <p className="mx-2 my-0 paraLorem">
                I agree to the {" "}
                <Link to="/TermsandConditions">Terms and conditions</Link> and <Link to="/PrivacyPolicy">Privacy Policy</Link>
              </p>
            </Form.Group>

            
            <button
              onClick={submitForm}
              type="submit"
              className="submitbtn text-white mb-3 mx-2"
            >
              {loading ?<Spinner animation="border" /> : `Submit` }
            </button>
            <br />
            <div id="APIMessageDiv">
              {errorMessage === "" && success !== "" ? (
                <div className="text-center">
                  <button
                className="submitbtn text-white mb-3 mx-2 thankyou"
              >
                {success}
              </button>
                </div>
              ) : (
                <span className="mx-3 " style={{ color: "red"}}>{errorMessage}</span>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubscribeModal;
