import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./AboutUsFirst.css"
import CardImg from '../../../IMG/Aboutus/Rectangle7.png'
import colanimg from "../../../IMG/Aboutus/colan.png"
import bgimgaboutusfirst from "../../../IMG/Aboutus/background/XMLID_13_ 2About.png"
import BgImgsun_mobile from "../../../IMG/Group_10.png";
import Slidersecond from './Slidersecond'
const AboutUsFirst = () => {
    return (
        <>
            <Container className='p-relative'>

               
                    <Slidersecond/>
                <Row>
                    <Col md={12} lg={5}>
                        <div className="tp-feature__AboutusFirst imgbgblur"> 
                        <img className="" src={bgimgaboutusfirst} style={{width:"100%"}}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={5}>
                        <div className="tp-feature__AboutusFirst_sunmobile imgbgblur"> 
                        <img className="" src={BgImgsun_mobile} style={{width:"100%"}}/>
                        </div>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default AboutUsFirst