import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import logo from "../../../IMG/TVLogo.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import SubscribeModal from "../../Modal/SubscribeModal";
import Androidlogo from "../../../IMG/Logos/Android.png";
import Ios from "../../../IMG/Logos/IOS.png";
import talentverstextlogo from "../../../IMG/Logos/Group328.png";
import talentverslogo from "../../../IMG/Logos/TVLogo.png";
import talentverslogopink from "../../../IMG/Group328.png";
// import talentverslogopink from "../../../IMG/mobilelogo.png";
import Frame from "../../../IMG/Frame.png";
import "./Navigation.css";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
function Navigation({ showLeftHeaderImg }) {
  const [showModal, setShowModal] = useState(false);

  const [scrollData, setScrollData] = useState({
    y: 0,
    lastY: 0,
  });

  const [showNav, setShowNav] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollData((prevState) => {
        return {
          y: window.scrollY,
          lastY: prevState.y,
        };
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollData.y > 700) {
      setShowNav(true);
    } else {
      setShowNav(false);
    }
    if (scrollData.y < 0 || scrollData.lastY < 0) {
      setShowNav(false);
    } else if (scrollData.lastY < scrollData.y) {
      setShowNav(true);
    } else {
      setShowNav(false);
    }
  }, [scrollData]);
  return (
    <>
      {["lg"].map((expand) => (
        <Navbar
          key={expand}
          bg="light"
          expand={expand}
          className={`mb-3 zindexclass  ${
            showNav ? `navbarEffect hideNav` : `navbarEffect`
          }${scrollData.y === 0 ? ` navbarEffect ` : ` changeColor`} `}
        >
          {/* {showLeftHeaderImg && (
            <img
              src={Frame}
              className="headerBackgroundImage d-none d-lg-block d-xl-block opacity-50"
            />
          )} */}
          <Container data-aos="fade-up" data-aos-duration="3000">
            <Row>
              <Col className="mt-3">
                <Navbar.Brand href="#">
                  <div className="">
                    <Link to="/">
                      <img className="logoclass" src={logo}></img>
                    </Link>
                    <Link to="/">
                      <img
                        className="talentverslogopinkclass"
                        src={talentverslogopink}
                      />
                    </Link>
                  </div>
                  <Navbar.Brand className="text-white d-none">
                    Talentverse
                  </Navbar.Brand>
                </Navbar.Brand>
              </Col>

              <Col className="">
                <div style={{ float: "right", marginTop: "10px" }}>
                  <Navbar.Toggle
                    aria-controls={`offcanvasNavbar-expand-${expand}`}
                  />
                </div>
              </Col>
            </Row>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Navbar.Brand>
                  <Link to="/">
                    <img
                      className="talentsverslogoclass"
                      src={talentverslogo}
                    />
                  </Link>
                </Navbar.Brand>
                <Offcanvas.Title
                  className="fontmodal"
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                >
                  <Link to="/">
                    <img
                      className="talentsverslogotextclass"
                      src={talentverstextlogo}
                    />
                  </Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 me-auto my-2 ">
                  <Nav.Link className="commingsoonhide">
                    <div>
                      <Link to="/">
                        <span className="fontmodal">Home</span>
                      </Link>
                    </div>
                  </Nav.Link>

                  <Nav.Link className=" fontmodal ml-5">
                    <Link className="anavigation" to="/Aboutus">
                      {" "}
                      About Talentverse{" "}
                    </Link>
                  </Nav.Link>
                  <Nav.Link className=" fontmodal ">
                    <Link className="anavigation" to="/features">
                      Features
                    </Link>
                  </Nav.Link>
                  {/* <Nav.Link className=" fontmodal"> */}
                  <a
                    className="text-white nav-link fontmodal"
                    href="https://talentverse.in/blogs/"
                  >
                    Blog & Stories
                  </a>
                  {/* </Nav.Link> */}

                  <Nav.Link className="commingsoonhide">
                    <img className="Androidlogoclass" src={Androidlogo} />
                    <div>
                      <span className="fontmodal p-2">Android App</span>
                      <span className=" coomingsoon">Coming Soon</span>
                    </div>
                  </Nav.Link>
                  <Nav.Link className="commingsoonhide">
                    <img className="Androidlogoclass" src={Ios} />
                    <div>
                      <span className="fontmodal p-2">ios App</span>
                      <span className=" coomingsoon">Coming Soon</span>
                    </div>
                  </Nav.Link>
                </Nav>
                <button
                  onClick={handleShowModal}
                  className="joinwaitlistbtn btn btn-info"
                >
                  Join Waitlist
                </button>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
      <SubscribeModal showModal={showModal} handleClose={handleCloseModal} />
    </>
  );
}

export default Navigation;
