import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import "./HomeScreenText.css";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import SubscribeModal from "../../Modal/SubscribeModal";
import Rectangle64 from "../../../IMG/Home/Rectangle 64.png"
import Rectangle65 from "../../../IMG/Home/Rectangle 65.png"
import Rectangle66 from "../../../IMG/Home/Rectangle 66.png"
import Rectangle67 from "../../../IMG/Home/Rectangle 67.png"
import Rectangle68 from "../../../IMG/Home/Rectangle 68.png"
import Rectangle69 from "../../../IMG/Home/Rectangle 69.png"
import Rectangle70 from "../../../IMG/Home/Rectangle 70.png"
import Rectangle71 from "../../../IMG/Home/Rectangle 71.png"
import Rectangle72 from "../../../IMG/Home/Rectangle 72.png"
import Rectangle73 from "../../../IMG/Home/Rectangle 73.png"
import Rectangle74 from "../../../IMG/Home/Rectangle 74.png"
import Rectangle75 from "../../../IMG/Home/Rectangle 75.png"
import Rectangle76 from "../../../IMG/Home/Rectangle 76.png"
import Rectangle78 from "../../../IMG/Home/Rectangle 78.png"
import Rectangle79 from "../../../IMG/Home/Rectangle 79.png"
import Rectangle80 from "../../../IMG/Home/Rectangle 80.png"
import gsap from "gsap";
import { Power2 } from 'gsap/all';


const HomeScreentext = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  }

  useEffect(() => {
    setTimeout(() => {
     
      
  
      var timeout;
      document.querySelector('.bg_Img').addEventListener("mousemove", function(e) {
          
          if(timeout) clearTimeout(timeout);
          setTimeout(callParallax.bind(null, e), 200);
      });

      
      
      const mouseMoveHandler = (e) => {
      
        if(timeout) clearTimeout(timeout);
        setTimeout(callParallax.bind(null, e), 200);
      };

      function callParallax(e){
      
        parallaxIt(e, '.circle', -100);
      
      }

      function parallaxIt(e, target, movement){
        if(window.screen.width>=1024){
          var k_this =document.getElementById("myDIV");
          if(k_this) {

            var relX = e.pageX - k_this.offsetLeft;
            var relY = e.pageY - k_this.offsetTop;
            var relX_n=(relX - k_this.offsetWidth/2) / k_this.offsetWidth * movement;
            var relY_n= (relY - k_this.offsetHeight/2) / k_this.offsetHeight * movement;
            gsap.to(target,{
              x: relX_n,
              y: relY_n,
              duration: 2, 
          
              ease: Power2.easeOut
            })
          }
        }
      
        
      }

       

    }, [100])
}, []);

  return (
    <>
      <Container id="myDIV">
        <Row>
          <Col className="text-center text-white Hometext">
            <div className="displayrelativehome">
              <div>
                <img src={Rectangle64} dataDepth="10" className="circle Rectangle64class circlewidth" />
              </div>
              <div>
                <img src={Rectangle65} dataDepth="5" className="circle Rectangle65class circlewidthsecond" />
              </div>
              <div>
                <img src={Rectangle66} dataDepth="10" className="circle Rectangle66class" />
              </div>

              <div>
                <img src={Rectangle67} dataDepth="10" className="circle Rectangle67class " />
              </div>
              <div>
                <img src={Rectangle68} dataDepth="10" className="circle Rectangle68class circlewidthsecond" />
              </div>
              <div>
                <img src={Rectangle69} dataDepth="10" className="circle Rectangle69class circlewidth" />
              </div>
              <div>
                <img src={Rectangle70} dataDepth="10" className="circle Rectangle70class circlewidthsecond" />
              </div>
              <div>
                <img src={Rectangle71} dataDepth="10" className="circle Rectangle71class circlewidth" />
              </div>
              <div>
                <img src={Rectangle72} dataDepth="10" className="circle Rectangle72class circlewidthsecond" />
              </div>
              <div>
                <img src={Rectangle73} dataDepth="10" className="circle Rectangle73class circlewidthsecond" />
              </div>
              <div>
                <img src={Rectangle74} dataDepth="10" className="circle Rectangle74class circlewidth" />
              </div>
              <div>
                <img src={Rectangle75} dataDepth="10" className="circle Rectangle75class circlewidth" />
              </div>
              <div>
                <img src={Rectangle76} dataDepth="10" className="circle Rectangle76class circlewidthsecond" />
              </div>
              <div>
                <img src={Rectangle78} dataDepth="10" className="circle Rectangle78class circlewidthsecond" />
              </div>
              <div>
                <img src={Rectangle79} dataDepth="10" className="circle Rectangle79class circlewidthsecond" />
              </div>
              <div>
                <img src={Rectangle80} dataDepth="10" className="circle Rectangle80class circlewidthsecond" />
              </div>

            </div>
            <div className="centertextclassdiv">
              <div className="texttop" data-aos="fade-up"  data-aos-duration="3000">
                <h1 className="fontSize">Unleash Your Potential</h1>
                <p className="mt-3 homep">
                  Welcome to Talentverse, a platform that empowers Artists, and
                  Creators to showcase their talent, connect with like-minded
                  individuals, fans, opportunities, and potential collaborators to
                  monetize their skills. Whether you're a seasoned professional or
                  just starting out, Talentverse empowers the creative community to
                  grow, and thrive in a digital age. Join today and unlock your full
                  potential as an Artist or Creator.
                 <p>
                  <p className="mt-md-5 mt-4">

                  <button
                    onClick={handleShowModal}
                    className="joinwaitlistbtn btn btn-info"
                  >
                    Join Waitlist
                  </button>
                  </p>
                 </p>
                </p>
              </div>
            </div>
          </Col>
        </Row>

      </Container>
      <SubscribeModal showModal={showModal} handleClose={handleCloseModal} />

      <div className="blacklineargradient"></div>
    </>
  );
};

export default HomeScreentext;
