import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./TheIdea.css"
import CardImgone from "../../../../IMG/Aboutus/InnovativeIdea.png"
import CardImgtwo from "../../../../IMG/Aboutus/NaveenKumar.png"
import CardImgthree from "../../../../IMG/Aboutus/VishwasRajanna.png"
import Tptheidea from "../../../../IMG/Aboutus/background/XMLID_13_ 3About.png"
import XMLID_14_mobile from "../../../../IMG/XMLID_14.png";
import EllipseRight_mobile from "../../../../IMG/EllipseRight.png";
const TheIdea = () => {
    return (
        <div>
            <Container  className='p-relative mt-5'>
                <Row className='marginDesktop'>
                    <Col data-aos="fade-up"  data-aos-duration="3000" lg={6} style={{zIndex:1}}>
                        <h1 className='theideaclass'>The Idea</h1>
                        <p className='theideacontant'>Talentverse was born out of the idea that many talented individuals in the artist, and creator industry struggle to showcase their skills, and get the exposure they deserve. Our founder, Naveen, saw the need for a Digital Talent Ecosystem that could connect these talents with opportunities to grow, learn, and monetize their skills, while giving users more control over what they see, and offering rewards in return.</p>
                        <p className='theideacontant'>
                        It's been an exciting journey so far, and we're thrilled to have been recognized for our innovative, and impactful idea by the Spotlight Achievement Award for the Best Innovative Idea of the Year 2023 in the field of Social Platform at the Global Business Line Summit 2023.
                        </p>
                    </Col>
                    <Col data-aos="fade-up"  data-aos-duration="3000" style={{zIndex:1}} lg={6}>
                        <Row className='align-items-center'>
                            <Col xs={6}>
                                <Row>
                                    <Col sm={12}>
                                        <div className='cardmaindiv'>
                                            <div>
                                                <img className='cardimg1' src={CardImgone} />
                                            </div>
                                            <div className='cardcontant1'>
                                                <span>Innovative Idea Award 2023</span>
                                                <br></br>
                                                <span>Talentverse receives the award from R. Gopinath Rao, Deputy Director at MSME.</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className='cardmaindiv mt-2'>
                                            <div>
                                                <img className='cardimg1' src={CardImgthree} />
                                            </div>
                                            <div className='cardcontant1'>
                                                <span>Vishwas Rajanna</span>
                                                <br></br>
                                                <span>Community Director</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={6} style={{paddingLeft:"0px"}}>
                                <div className='thirdimg'>
                                    <div className='cardmaindiv'>
                                        <div>
                                            <img className='cardimg' src={CardImgtwo} />
                                        </div>
                                        <div className='cardcontant'>
                                            <span>Naveen Kumar A.</span>
                                            <br></br>
                                            <span>Founder & CEO</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={5}>
                        <div className="tp-feature__Theidea imgbgblur"> 
                        <img className="" src={Tptheidea} style={{width:"100%"}}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={5}>
                        <div className="tp-feature__Mobile_right imgbgblur"> 
                        <img className="" src={XMLID_14_mobile} style={{width:"100%"}}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={5}>
                        <div className="tp-feature__Mobile_right_second imgbgblur"> 
                        <img className="" src={XMLID_14_mobile} style={{width:"100%"}}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={5}>
                        <div className="tp-feature__Mobile_right_Three imgbgblur"> 
                        <img className="" src={XMLID_14_mobile} style={{width:"100%"}}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={5}>
                        <div className="tp-feature__EllipseRight_mobile imgbgblur"> 
                        <img className="" src={EllipseRight_mobile} style={{width:"100%"}}/>
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </div>
    )
}

export default TheIdea

