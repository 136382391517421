import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Container, Row, COl, Col } from "react-bootstrap";

const Slidercomponet = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <>
            <Container className='p-relative'>
                <Row>
                    <Col>
                        <h1 className='Aboutusclass displayslider'>About Us</h1>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ zIndex: 1 }}>

                        <div className="displayslider">



                            <Slider {...settings}>
                                <div>
                                    <h1 className="sliderheadingfont">Brief About Talentverse</h1>
                                    <p className="tabclasss" >At Talentverse, we believe that everyone deserves a chance to showcase their talents, and connect with like-minded individuals. We are designing a platform to bring together a diverse community of artists, creators, and learners, providing them with the tools and resources they need to thrive. Whether you're a seasoned professional or just starting out, Talentverse is here to support you on your journey.
                                    </p>
                                    <p className='tabcontant'>

                                        We strive to create a platform that benefits both our users and artists, placing our community of creators and users at the heart of our mission.
                                    </p>
                                </div>
                                <div>
                                    <h1 className="sliderheadingfont">What Inspired Us?</h1>
                                    <p className="tabclasss" >Our journey began with a simple observation: there are countless talented individuals out there who lack the resources and opportunities they need to succeed. We saw this as a problem that needed solving, and decided to create a platform that could bring together people from all walks of life, united by their passion for creativity and learning. Our goal is to empower individuals to take control of their own destinies and build meaningful, fulfilling careers doing what they love.</p>
                                </div>
                                <div>
                                    <h1 className="sliderheadingfont">Our Mission</h1>
                                    <p className="tabclasss" >
                                        Talentverse's mission is to create a user-centric platform that inspires, empowers and connects talents and learners from all backgrounds, enabling them to discover their potential, collaborate, learn, and grow.

                                    </p>
                                    <p className='tabcontant'>
                                        We strive to foster a supportive and inclusive environment that enables everyone to benefit, and to provide opportunities for personal and professional development, while promoting positive change in the world.
                                    </p>
                                </div>
                                <div>
                                    <h1 className="sliderheadingfont">Our Vision </h1>
                                    <p className="tabclasss" >To be the North Star for all forms of Talent.

                                    </p>
                                    <p className='tabcontant'>
                                        Talentverse aims to be a guiding light for everyone to discover, and showcase their talent, and create a world where anyone can pursue their dreams, and achieve their full potential, regardless of their background, or circumstances. We believe that by providing a supportive, inclusive platform for talents and learners, we can help to unlock the power of human potential, and drive positive change in the world.
                                    </p>
                                </div>

                            </Slider>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Slidercomponet