import React from 'react'
import "./wifi.css"
import Rectangle64 from "../../IMG/wifi photos/Rectangle56.png"
import Rectangle59 from "../../IMG/wifi photos/Rectangle57.png"
import Rectangle67 from "../../IMG/wifi photos/Rectangle58.png"
import Rectangle66 from "../../IMG/wifi photos/Rectangle59.png"
import Rectangle71 from "../../IMG/wifi photos/Rectangle60.png"     
import Rectangle69 from "../../IMG/wifi photos/Rectangle61.png"
import Rectangle68 from "../../IMG/wifi photos/Rectangle62.png"
import Rectangle65 from "../../IMG/wifi photos/Rectangle63.png"
import Rectangle72 from "../../IMG/wifi photos/Rectangle64.png"
import Rectangle70 from "../../IMG/wifi photos/Rectangle65.png"
import Rectangle56 from "../../IMG/wifi photos/Rectangle66.png"
import Rectangle62 from "../../IMG/wifi photos/Rectangle67.png"
import Rectangle60 from "../../IMG/wifi photos/Rectangle68.png"
import Rectangle61 from "../../IMG/wifi photos/Rectangle69.png"
import Rectangle58 from "../../IMG/wifi photos/Rectangle70.png"
import Rectangle57 from "../../IMG/wifi photos/Rectangle71.png"
import Rectangle63 from "../../IMG/wifi photos/Rectangle72.png"
import Ellipse10 from "../../IMG/wifi photos/Ellipse10.png"

import TVLogo from "../../IMG/wifi photos/TVLogo.png"

const Wifi = ({fromPage}) => {
    return (

        <div>
            <div className={`${fromPage === 'innovative' ? `marginTopInnovation` : 'marginTopHomePageAllSectionResponsive'} overflow-hidden`}>
                <div className='innerSection m-auto position-relative'>
                    <img src={Ellipse10} className='purpleBG' />
                    <img className='Rectangle56 wifibigimg' src={Rectangle56} />
                    <img className='Rectangle57 wifismallimg' src={Rectangle57} />
                    <img className='Rectangle58 wifibigimg' src={Rectangle58} />
                    <img className='Rectangle59 wifimidimg' src={Rectangle59} />
                    <img className='Rectangle60 wifibigimg' src={Rectangle60} />
                    <img className='Rectangle61 wifibigimg' src={Rectangle61} />
                    <img className='Rectangle62 wifibigimg' src={Rectangle62} />
                    <img className='Rectangle63 wifimidimg' src={Rectangle63} />
                    <img className='Rectangle64 wifimidimg' src={Rectangle64} />
                    <img className='Rectangle65 wifismallimg' src={Rectangle65} />
                    <img className='Rectangle66 wifimidimg' src={Rectangle66} />
                    <img className='Rectangle67 wifimidimg' src={Rectangle67} />
                    <img className='Rectangle68 wifismallimg' src={Rectangle68} />
                    <img className='Rectangle69 wifismallimg' src={Rectangle69} />
                    <img className='Rectangle70 wifismallimg' src={Rectangle70} />
                    <img className='Rectangle71 wifismallimg' src={Rectangle71} />
                    <img className='Rectangle72 wifismallimg' src={Rectangle72} />
                    <img className='TVLogo wifibigimg ' src={TVLogo} />
                    <div class="spinner-wrap">
                        <div class="spinner-item"></div>
                        <div class="spinner-item spinner-item--2"></div>
                        <div class="spinner-item spinner-item--3"></div>
                        <div class="spinner-item spinner-item--4"></div>
                    </div>
                </div>
            </div>
        </div>





        // <div >
        //     <div className='text-center p-5 backgroundwifi' >
        //         <img src={Ellipse10} className='position-absolute h-100'/>
        //         {/* <div className='baoderwifi'></div>
        //         <div className='baoderwifi4'></div>
        //         <div className='baoderwifi3'></div>
        //         <div className='baoderwifi2'> </div> */}
        //         <div class="spinner-wrap">
        //             <div class="spinner-item"></div>
        //             <div class="spinner-item spinner-item--2"></div>
        //             <div class="spinner-item spinner-item--3"></div>
        //         </div>

        //         <div className='maindivwifi'>

        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle56' src={Rectangle56} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle57' src={Rectangle57} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle58' src={Rectangle58} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle59' src={Rectangle59} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle60' src={Rectangle60} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle61' src={Rectangle61} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle62' src={Rectangle62} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle63' src={Rectangle63} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle64' src={Rectangle64} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle65' src={Rectangle65} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle66' src={Rectangle66} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle67' src={Rectangle67} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle68' src={Rectangle68} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle69' src={Rectangle69} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle70' src={Rectangle70} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle71' src={Rectangle71} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='Rectangle72' src={Rectangle72} />
        //             </div>
        //             <div>
        //                 <img data-aos="fade-up"  data-aos-duration="3000" className='TVLogo' src={TVLogo} />
        //             </div>
        //         </div>


        //     </div>
        // </div>
    )
}

export default Wifi
