import React, { useEffect, useState } from "react";
import Img2 from "../../../../IMG/StorieSlider/Img2_new.jpg";
import Img3 from "../../../../IMG/StorieSlider/Img3.jpeg";
import Img4 from "../../../../IMG/StorieSlider/Img1.jpeg";
import left from "../../../../IMG/left.png";
import right from "../../../../IMG/right.png";
import "./StoriesSlider.css";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function StoriesSlider() {
  var activeImage = 0;

  const [sliderImages, setSliderImages] = useState([
    {
      id: 1,
      img: Img4,
      name: "Kunem Ao",
      description: `Stubborn is what "Kunem" stands for, so I am not going to stop until every ear has heard the music of my heart.`,
      class: "testImgActive",
    },
    {
      id: 2,
      img: Img2,
      name: "Vishwas Rajanna",
      description: `I love to beatbox, and nothing gives me more joy than getting on stage, keeping the crowd engaged, and spreading my love for music.`,
      class: "",
    },
    {
      id: 3,
      img: Img3,
      name: "Abisheak Samson",
      description: `I had a shoulder injury, but my love for Boxing kept me going, and today I am a National level boxer, and won multiple tournaments.`,
      class: "",
    },
    {
      id: 4,
      img: Img4,
      name: "Kunem Ao",
      description: `Stubborn is what "Kunem" stands for, so I am not going to stop until every ear has heard the music of my heart.`,
      class: "",
    },
    {
      id: 5,
      img: Img2,
      name: "Vishwas Rajanna",
      description: `I love to beatbox, and nothing gives me more joy than getting on stage, keeping the crowd engaged, and spreading my love for music.`,
      class: "",
    },
    {
      id: 6,
      img: Img3,
      name: "Abisheak Samson",
      description: `I had a shoulder injury, but my love for Boxing kept me going, and today I am a National level boxer, and won multiple tournaments.`,
      class: "",
    },
  ]);

  useEffect(() => {
    var carousels = document.querySelectorAll(".carousel");
    for (var i = 0; i < carousels.length; i++) {
      carousel(carousels[i]);
    }
  },[])



  function carousel(root) {
    var figure = root.querySelector("figure"),
      nav = root.querySelector("nav"),
      images = figure.children,
      n = images.length,
      gap = root.dataset.gap || 0,
      bfc = "bfc" in root.dataset,
      theta = (2 * Math.PI) / n,
      currImage = 0;
    setupCarousel(n, parseFloat(getComputedStyle(images[0]).width));
    window.addEventListener("resize", () => {
      setupCarousel(n, parseFloat(getComputedStyle(images[0]).width));
    });

    setupNavigation();

    function setupCarousel(n, s) {
      var apothem = s / (2 * Math.tan(Math.PI / n));
      figure.style.transformOrigin = `50% 50% ${-apothem}px`;

      for (var i = 0; i < n; i++) images[i].style.padding = `${gap}px`;
      for (i = 1; i < n; i++) {
        images[i].style.transformOrigin = `50% 50% ${-apothem}px`;
        images[i].style.transform = `rotateY(${i * theta}rad)`;
      }
      if (bfc)
        for (i = 0; i < n; i++) images[i].style.backfaceVisibility = "hidden";

      rotateCarousel(currImage);
    }

    function setupNavigation() {
      nav.addEventListener("click", onClick, true);

      function onClick(e) {
        e.stopPropagation();

        var t = e.target;

        if (t.classList.contains("next")) {
          currImage++;
          activeImage = activeImage + 1;
          if (activeImage == 6) {
            activeImage = 0;
          }
          var newSlider = sliderImages.map((ele, index) => {
            if (index == activeImage) {
              ele["class"] = "testImgActive";
            } else {
              ele["class"] = "";
            }
            return ele;
          });
          setSliderImages(newSlider);
        } else {
          currImage--;
          activeImage = activeImage - 1;
          if (activeImage < 0) {
            activeImage = 5;
          }
          var newSlider = sliderImages.map((ele, index) => {
            if (index == activeImage) {
              ele["class"] = "testImgActive";
            } else {
              ele["class"] = "";
            }
            return ele;
          });
          setSliderImages(newSlider);
        }

        rotateCarousel(currImage);
      }
    }

    function rotateCarousel(imageIndex) {
      figure.style.transform = `rotateY(${imageIndex * -theta}rad)`;
    }
  }

  return (
    <>
      <div class="carousel" data-gap="20" data-bfc>
        <figure>
          {sliderImages.map((ele) => {
            return (
              <div className="subDiv">
                <div className={`testImg position-relative ${ele.class}`}>
                  <img src={ele.img} />
                  <div className="sliderContent bottom-0 w-100 px-lg-4 py-lg-3 px-md-4 py-md-3 px-2 py-2 position-absolute">
                    <h3 className="sliderTitle m-0">{ele.name}</h3>
                    <p className="sliderDesc m-0 mt-lg-2 mt-md-2 mt-1">
                      {ele.description}
                    </p>
                    <div className="row align-items-center justify-content-center mt-1">
                      <Link  className="sliderBtn" to ="http://blog.talentverse.in/stories">Learn More 
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </figure>
        <nav>
          <Row className="m-0 justify-content-center">
            <Col className="px-2" style={{ cursor: "pointer" }}>
              <img src={left} style={{ width: "35px" }} />
            </Col>
            <Col className="px-2" style={{ cursor: "pointer" }}>
              <img src={right} className="next" style={{ width: "35px" }} />
            </Col>
          </Row>
        </nav>
      </div>
    </>
  );
}

export default StoriesSlider;
