import React from "react";
import "./Versatiletool.css";
import { Col, Container, Row } from "react-bootstrap";
import versatile_mobile from "../../../IMG/Frame 3122.png";
import arrow from "../../../IMG/arrow.png";
import BgImg from "../../../IMG/Group_10.png";
import rightlogo from "../../../IMG/Logo/Vectorright.png";
import BackgroundLeft from "../../../IMG/innovative/XMLID_13.png";
import { Link } from "react-router-dom";

const Versatiletool = ({ fromPage }) => {
  return (
    <>
      <div
        className={`p-relative ${
          fromPage === "innovative" ? `marginTop_innovative_page` : "marginTopHomePageAllSectionResponsive"
        }`}
      >
        <Container>
          {fromPage === "innovative" ? (
            <img
              className="tp-feature__bottom-shape__innovative_page_left d-lg-none opacity-50"
              src={BackgroundLeft}
            />
          ) : null}
          <Row>
            <Col
              className={`third ${
                fromPage === "innovative" ? `order-last` : `order-md-first`
              } upperIndex`}
              md={12}
              lg={7}
            >
              <Row>
                <Col data-aos="fade-up" data-aos-duration="3000">
                  <h6 className="fontFeatures">Our Features</h6>
                  <h1 className="VersatileTools my-3">Versatile Tools</h1>
                  <p
                    className="hleperclasss mb-lg-5"
                    style={{ color: "#9B9B9A" }}
                  >
                    Talentverse offers a range of versatile tools to help you
                    showcase
                    <br />
                    your talent, and reach your goals.
                  </p>
                </Col>
                <Col
                  className="d-sm-block d-lg-none text-center"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                  md={12}
                  lg={5}
                >
                  <img
                    className="versatile_mobile_class"
                    src={versatile_mobile}
                  />
                </Col>
              </Row>
              <Row>
                <Helper
                  Heading="Showcase your talent"
                  contant="Create a stunning profile, and showcase your talent to the world."
                />

                <Helper
                  Heading="Community Building"
                  contant="Join a supportive community of talented creators."
                />
                <Helper
                  Heading="Opportunity Discovery"
                  contant="Find new opportunities to monetize your skills."
                />
                <Helper
                  Heading=" Event Exploration"
                  contant="Discover exciting events, and content tailored to you."
                />
                {fromPage === "innovative" ? (
                  <>
                    <Helper
                      Heading="Collaboration Hub"
                      contant="Connect with artists and creators to collaborate and grow."
                    />
                    <Helper
                      Heading="Skill Improvement"
                      contant="Enhance your skills with personalized course offerings."
                    />
                  </>
                ) : (
                  ""
                )}
                <Col className="btncenter mt-2 mb-5" data-aos="fade-up" data-aos-duration="3000">
                {fromPage != "innovative" ? (
                  <button className="btn buttons"><Link to="/features" style={{color:"white"}}> See All </Link></button>):""
                }
                </Col>
              </Row>
            </Col>
            <Col
              className="d-none d-md-none d-sm-block d-lg-block"
              md={12}
              lg={5}
              style={{ textAlign: "center" }}
            >
              <img
                data-aos="fade-up"
                data-aos-duration="3000"
                className="versatile_mobile_class"
                src={versatile_mobile}
              />
            </Col>
          </Row>
        </Container>

        <Row>
          <Col md={12} lg={5}>
            {fromPage === "innovative" ? (
              <div className="tp-feature__bottom-shape__innovative_page imgbgblur">
                <img
                  className=""
                  src={BackgroundLeft}
                  style={{ width: "100%" }}
                />
              </div>
            ) : (
              <div className="tp-feature__bottom-shape imgbgblur">
                <img className="" src={BgImg} style={{ width: "100%" }} />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Versatiletool;

function Helper(props) {
  return (
    <>
      <Col
        data-aos="fade-up"
        data-aos-duration="3000"
        md={12}
        lg={6}
        className=""
      >
        {/* <img src={arrow} height={21} width={21} style={{marginRight:"10px"}} className="d-lg-none" /> */}
        <div className="p-0 hleperclassscontanct" style={{ color: "#9B9B9A" }}>
          <p className="text-white my-2">
            <img className="rightclass" src={rightlogo} />
            <b className="">{props.Heading}</b>
          </p>
          <p className="mb-4 marginleftour">{props.contant}</p>
        </div>
      </Col>
    </>
  );
}
