import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Discoverimg from "../../../IMG/Frame 3121.png";
import XMLID_14 from "../../../IMG/XMLID_14.png";
import "./Discover.css";
const Discover = () => {
  return (
    <>
      <div className="bgdiscover p-relative marginTopHomePageAllSectionResponsive">
        <Container>
          <Row>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              lg={{ span: 4, order: 1 }}
              md={{ order: 2 }}
              sm={{ order: 2 }}
              xs={{ order: 2 }}
              style={{textAlign:'center'}}
              className="upperIndex"
            >
              <img className="Discoverimg" src={Discoverimg} />
            </Col>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              className="discovercontant upperIndex"
              lg={{ span: 8, order: 2 }}
              md={{ order: 1 }}
              sm={{ order: 1 }}
              xs={{ order: 1 }}
            >
              <p className="discoverMore">Discover More</p>
              <h1 className="boldcontant">Find Your Inspiration</h1>
              <p className="discoverMoreContent mt-2 mb-4" style={{ color: "#9B9B9A" }}>
                Get inspired by the creative works of others, and<br/> explore a vast
                collection of art, music, photography,<br/> and
                more. With Talentverse, the possibilities for<br/>
                inspiration are endless.
              </p>
            </Col>
          </Row>
        </Container>
        <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__bottom-shapeshape_discover imgbgblur"> 
                <img className="" src={XMLID_14} style={{width:"100%"}}/>
                </div>
            </Col>
          </Row>
      </div>
    </>
  );
};

export default Discover;
