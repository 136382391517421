import React from "react";
import "./Newtalent.css";
import card1img1 from "../../../IMG/card/Frame 81.png"
import card1img2 from "../../../IMG/card/Frame 82.png"
import card1img3 from "../../../IMG/card/Frame 83.png"
import card1img4 from "../../../IMG/card/Frame 84.png"
import card1img5 from "../../../IMG/card/Frame 85.png"
import card1img6 from "../../../IMG/card/Frame 86.png"
import logo from "../../../IMG/TVLogo.png";
import XMLID_13 from "../../../IMG/XMLID_13.png";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
const Newtalent = () => {
  return (
    <>
      <div className="p-relative  marginTopHomePageDiscoverNewSectionResponsive mt-lg-5 mt-xxl-5" >
        <Container>
          <Row>
            <Col data-aos="fade-up"  data-aos-duration="3000" className="newcontant text-white " lg={6}>
              <p style={{ margin: "1px" }} className="title">
                Featured Content
              </p>
              <h1 className="boldcontant">Discover New Talent</h1>
              <p className="newcontantDesc" style={{ color: "#9B9B9A" }}>
                Explore a selection of top artists, and creators from
                <br /> various fields, and unlock a world of creativity with{" "}
                <br />
                exclusive courses, content, and various other services,
                <br /> only on Talentverse.
              </p>
            </Col>
            <Col lg={6} data-aos="fade-up"  data-aos-duration="3000" className="d-flex justify-content-center"> 
              <Row className="mb-4 ">
                <Col 
                  lg={4}
                  sm={4}
                  xs={4}
                  className="d-flex flex-column col-lg-4 col-sm-4 col-4 justify-content-center align-items-center align-items-md-end"
                >
                  <Card className="newtalentCardWrapper mb-4">
                    <Card.Img src={card1img1} />
                    {/* <Card.ImgOverlay>
                      <div className="newcontantAvtarImgWrapper">
                        <div className="newcontantAvtarImg">
                          <img src={bianca} height={48} width={48} />
                        </div>
                        <div className="contentWrapper">
                          <p className="contentTitle">breccia.riccardo</p>
                          <p className="contentSubTitle">Lv 14</p>
                          <button className="btn contentButton">Follow</button>
                          <p className="contentDesc">
                            Followed by dshfa.hg + 2more
                          </p>
                        </div>
                      </div>
                    </Card.ImgOverlay> */}
                  </Card>
                  <Card className="newtalentCardWrapper">
                    <Card.Img src={card1img2} />
                    {/* <Card.ImgOverlay>
                      <div className="newcontantAvtarImgWrapper">
                        <div className="newcontantAvtarImg">
                          <img src={bianca} height={48} width={48} />
                        </div>
                        <div className="contentWrapper">
                          <p className="contentTitle">breccia.riccardo</p>
                          <p className="contentSubTitle">Lv 14</p>
                          <button className="btn contentButton">Follow</button>
                          <p className="contentDesc">
                            Followed by dshfa.hg + 2more
                          </p>
                        </div>
                      </div>
                    </Card.ImgOverlay> */}
                  </Card>
                </Col>
                <Col
                  lg={4}
                  sm={4}
                  xs={4}
                  className="d-flex flex-column justify-content-around align-items-center"
                >
                  <Card className="newtalentCardWrapper mb-4">
                    <Card.Img src={card1img3} />
                    {/* <Card.ImgOverlay>
                      <div className="newcontantAvtarImgWrapper">
                        <div className="newcontantAvtarImg">
                          <img src={bianca} height={48} width={48} />
                        </div>
                        <div className="contentWrapper">
                          <p className="contentTitle">breccia.riccardo</p>
                          <p className="contentSubTitle">Lv 14</p>
                          <button className="btn contentButton">Follow</button>
                          <p className="contentDesc">
                            Followed by dshfa.hg + 2more
                          </p>
                        </div>
                      </div>
                    </Card.ImgOverlay> */}
                  </Card>
                  <div className="mt-5 mb-5">
                    <img src={logo} className="newtalentLogo" />
                  </div>
                  <Card className="newtalentCardWrapper">
                    <Card.Img src={card1img4} />
                    {/* <Card.ImgOverlay>
                      <div className="newcontantAvtarImgWrapper">
                        <div className="newcontantAvtarImg">
                          <img src={bianca} height={48} width={48} />
                        </div>
                        <div className="contentWrapper">
                          <p className="contentTitle">breccia.riccardo</p>
                          <p className="contentSubTitle">Lv 14</p>
                          <button className="btn contentButton">Follow</button>
                          <p className="contentDesc">
                            Followed by dshfa.hg + 2more
                          </p>
                        </div>
                      </div>
                    </Card.ImgOverlay> */}
                  </Card>
                </Col>
                <Col
                  lg={4}
                  sm={4}
                  xs={4}
                  className="d-flex flex-column col-lg-4 col-sm-4 col-4 justify-content-center align-items-center align-items-md-start"
                >
                  <Card className="newtalentCardWrapper mb-4">
                    <Card.Img src={card1img5} />
                    {/* <Card.ImgOverlay>
                      <div className="newcontantAvtarImgWrapper">
                        <div className="newcontantAvtarImg">
                          <img src={bianca} height={48} width={48} />
                        </div>
                        <div className="contentWrapper">
                          <p className="contentTitle">breccia.riccardo</p>
                          <p className="contentSubTitle">Lv 14</p>
                          <button className="btn contentButton">Follow</button>
                          <p className="contentDesc">
                            Followed by dshfa.hg + 2more
                          </p>
                        </div>
                      </div>
                    </Card.ImgOverlay> */}
                  </Card>
                  <Card className="newtalentCardWrapper">
                    <Card.Img src={card1img6} />
                    {/* <Card.ImgOverlay>
                      <div className="newcontantAvtarImgWrapper">
                        <div className="newcontantAvtarImg">
                          <img src={bianca} height={48} width={48} />
                        </div>
                        <div className="contentWrapper">
                          <p className="contentTitle">breccia.riccardo</p>
                          <p className="contentSubTitle">Lv 14</p>
                          <button className="btn contentButton">Follow</button>
                          <p className="contentDesc">
                            Followed by dshfa.hg + 2more
                          </p>
                        </div>
                      </div>
                    </Card.ImgOverlay> */}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Row>
              <Col md={12} lg={5}>
                <div className="tp-feature__bottom-shapeshape_newTalent imgbgblur" > 
                  <img className="" src={XMLID_13} style={{width:"100%"}}/>
                  </div>
              </Col>
            </Row>
      </div>
    </>
  );
};

export default Newtalent;
