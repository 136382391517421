import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./TermsandConditions.css";
import Navigation from "../Home/Navigation/Navigation";
import Footer from "../Footer/Footer";
import imgbgpvtright from "../../IMG/innovative/XMLID_1_.png";
import imgbgpvtleft from "../../IMG/innovative/XMLID_12.png";
import XMLID_14_tc from "../../IMG/XMLID_14.png";
import Frame from "../../IMG/Frame.png";

const TermsandConditions = () => {
  return (
    <div>
      <Container
        fluid
        className="main p-relative"
        style={{ marginTop: "150px" }}
      >
        <Navigation />
        <Container className="py-4">
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              lg={12}
              xs={12}
              style={{ border: "0px solid red", zIndex: "11" }}
              className="text-center"
            >
              <h1 className="headingnew">TERMS AND CONDITIONS</h1>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red", zIndex: "11" }}
            >
              <p>
                “Welcome to Talentverse! Throughout this page, "Talentverse,"
                "us," "we," or "our" refer to the Talentverse platform and its
                services. Talentverse is a platform where artists and creators
                can showcase and monetize their talents, and creators can
                showcase and monetize their talents. By using our website, you
                agree to comply with and be bound by the following terms and
                conditions of use. Please read these terms carefully before
                using our site.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red", zIndex: "11" }}
              className="text-left"
            >
              <p className="m-0">ACCEPTANCE OF TERMS</p>
              <p>
                By accessing our website, you acknowledge that you have read,
                understood, and agree to be bound by these terms and conditions.
                If you do not agree to these terms, please do not use our site.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red", zIndex: "11" }}
              className="text-left"
            >
              <p className="m-0">USE OF THE WEBSITE</p>
              <p>
                You agree to use our website only for lawful purposes and in a
                way that does not infringe upon the rights of others. You also
                agree to not use our site for any illegal or unauthorized
                purpose.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red", zIndex: "11" }}
              className="text-left"
            >
              <p className="m-0">REGISTRATION</p>
              <p>
                In order to use certain features of our website, you may need to
                register and create an account. You agree to provide accurate
                and complete information when registering and to keep your
                account information updated. You are responsible for maintaining
                the confidentiality of your account and password, and for
                restricting access to your computer.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red", zIndex: "11" }}
              className="text-left"
            >
              <p className="m-0">INTELLECTUAL PROPERTY</p>
              <p>
                All content on our website, including but not limited to text,
                graphics, logos, images, and software, is the property of
                Talentverse or its licensors and is protected by intellectual
                property laws. You may not use any of our content without our
                express written consent.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red", zIndex: "11" }}
              className="text-left"
            >
              <p className="m-0">USER CONTENT</p>
              <p>
                By submitting content to our website, you grant us a
                non-exclusive, transferable, sub-licensable, royalty-free,
                worldwide license to use, modify, and distribute your content.
                You are responsible for ensuring that any content you submit is
                original, does not infringe upon the rights of others, and
                complies with our community guidelines.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red", zIndex: "11" }}
              className="text-left"
            >
              <p className="m-0">THIRD-PARTY LINKS</p>
              <p>
                Our website may contain links to third-party websites that are
                not owned or controlled by Talentverse. We are not responsible
                for the content, privacy policies, or practices of any
                third-party websites.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red", zIndex: "11" }}
              className="text-left"
            >
              <p className="m-0">DISCLAIMER OF WARRANTIES</p>
              <p>
                Our website is provided "as is" and without warranty of any
                kind. We make no guarantees that our website will meet your
                requirements, be available uninterrupted, or be error-free.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red", zIndex: "11" }}
              className="text-left"
            >
              <p className="m-0">LIMITATION OF LIABILITY</p>
              <p>
                In no event shall Talentverse be liable for any damages, whether
                direct, indirect, incidental, special, or consequential, arising
                out of or in connection with your use of our website.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red", zIndex: "11" }}
              className="text-left"
            >
              <p className="m-0">INDEMNIFICATION</p>
              <p>
                You agree to indemnify and hold harmless Talentverse and its
                affiliates, directors, officers, employees, and agents from and
                against any claims, actions, suits, or proceedings, as well as
                any and all damages, liabilities, costs, and expenses, including
                reasonable attorneys' fees, arising out of or in connection with
                your use of our website.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red", zIndex: "11" }}
              className="text-left"
            >
              <p className="m-0">MODIFICATION OF TERMS</p>
              <p>
                We reserve the right to modify these terms and conditions at any
                time without notice. By continuing to use our website after we
                post any changes, you agree to be bound by the modified terms.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red", zIndex: "11" }}
              className="text-left"
            >
              <p className="m-0">GOVERNING LAW</p>
              <p>
                These terms and conditions shall be governed by and construed in
                accordance with the laws of the jurisdiction in which
                Talentverse is located, without giving effect to any principles
                of conflicts of law.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red", zIndex: "11" }}
              className="text-left"
            >
              <p>
                {" "}
                If you have any questions or concerns about our terms and
                conditions, please contact us at{" "}
                <a
                  className="text-lowercase text-decoration-underline text-white"
                  href="mailto:hello@talentverse.in"
                >
                  hello@talentverse.in
                </a>
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__TermsandConditions_top opacity-25">
                <img
                  className=""
                  src={imgbgpvtright}
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__TermsandConditions_top_menu d-none d-lg-block d-xl-block opacity-50">
                <img className="" src={Frame} style={{ width: "100%" }} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__TermsandConditions_down opacity-50">
                <img
                  className=""
                  src={imgbgpvtleft}
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__TermsandConditions-right opacity-50">
                <img className="" src={XMLID_14_tc} style={{ width: "100%" }} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__TermsandConditions-right-second opacity-50">
                <img className="" src={XMLID_14_tc} style={{ width: "100%" }} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__TermsandConditions-right-Three_ opacity-50">
                <img className="" src={XMLID_14_tc} style={{ width: "100%" }} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__TermsandConditions_down_left opacity-50">
                <img
                  className=""
                  src={imgbgpvtleft}
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__TermsandConditions_down_left_Second opacity-50">
                <img
                  className=""
                  src={imgbgpvtleft}
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </Container>
    </div>
  );
};

export default TermsandConditions;
