import React, { useState } from "react";
import "./Innovative.css";
import Navigation from "../Home/Navigation/Navigation";
import Musical from "./Musical";
import Versatiletool from "../Home/Versatiletool/Versatiletool";
import Levelup from "../Home/Levelup/Levelup";
import Stories from "../Home/Stories/Stories";
import Wifi from "../Wifi/Wifi";
import Footer from "../Footer/Footer";


const Innovative = () => {
    return (
        <>
            <Navigation showLeftHeaderImg={true} />
            <Musical/>
            <Versatiletool fromPage={"innovative"} />
            <Levelup fromPage={"innovative"} />
            <Stories fromPage={'innovative'}  />
            <Wifi fromPage={"innovative"} />
            <Footer />
        </>
    )
}

export default Innovative;