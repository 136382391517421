import { Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import Home from "./Banner/Home";
import Dimensions from "./Dimensions/Dimensions";
import Discover from "./Discover/Discover";
import Levelup from "./Levelup/Levelup";
import Newtalent from "./Newtalent/Newtalent";
import Stories from "./Stories/Stories";
import Versatiletool from "./Versatiletool/Versatiletool";
import Wifi from "../Wifi/Wifi";
import Slideranimation from "./Slidercomponent/Slideranimation";

function Index() {
  return (
    <>
      <Home />
      <Dimensions />
      <Versatiletool />
      <Discover />
      <Newtalent />
      <Levelup />
      <Slideranimation/>
      <Stories />
      <Wifi />
      <Footer />
    </>
  );
}

export default Index;
