import React from 'react'
import AboutUsFirst from './AboutUsFirst/AboutUsFirst'
import TheIdea from './AboutUsFirst/TheIdea/TheIdea'
import "./Aboutus.css"
import Whatpeoples from './Whatpeople/Whatpeoples'
import Imgfull from './IMGFULL/Imgfull'
import About from './About/About'
import Navigation from '../Home/Navigation/Navigation'
import Footer from '../Footer/Footer'
import Wifi from '../Wifi/Wifi'
import Slideranimation from '../Home/Slidercomponent/Slideranimation'


const Aboutus = () => {
  return (
    <>
      <div className='blackbg'>
        <Navigation />
        <About />
        <Imgfull />
        <AboutUsFirst />
        <TheIdea />
        <Whatpeoples />
        <Wifi />
        <Footer />
      </div>
     
    </>
  )
}

export default Aboutus




