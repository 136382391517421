import React, { useState } from "react";
import "./Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../IMG/Footer/Layer 2.png";
import playstore from "../../IMG/Footer/image 2.png";
import fb from "../../IMG/Footer/fb.png";
import insta from "../../IMG/Footer/insta.png";
import twiter from "../../IMG/Footer/twiter.png";
import youtube from "../../IMG/Footer/toutube.png";
import linked from "../../IMG/Footer/linkedIn.png";
import SubscribeModal from "../Modal/SubscribeModal";
import Footerimg_Down from "../../IMG/Frame_footer.png"
import { Link } from "react-router-dom";
const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  }
  const handleCloseModal = () => {
    setShowModal(false);
  }
  return (
    <>
      <div style={{ backgroundColor: "black" }}>
        <Container data-aos="fade-up"  data-aos-duration="3000" className="p-relative">
          <Row className="p-3">
            <Col  className="footerCenterContent" lg={6} md={6} xl={3}>
            <Link to = "/"><img className="logo" src={logo} /></Link>
              <div className="footerIcon mt-3">
              <a href="https://www.facebook.com/Talentverse.hq?mibextid=LQQJ4d" target="_blank"><img src={fb} /> </a>
              <a href="https://instagram.com/talentverse_hq?igshid=YmMyMTA2M2Y=" target="_blank"> <img src={insta} /></a>
              <a href="https://twitter.com/talentverse_hq?s=21&t=PnffR-31fJ-AXJPd6iDANw" target="_blank"> <img src={twiter} /></a>
              <a href="https://youtube.com/@talentverse_hq" target="_blank"> <img src={youtube} /></a>
              <a href="https://www.linkedin.com/company/talentversehq/" target="_blank"><img src={linked} /></a>
              </div>
            </Col>
            <Col  className="p-3 footerCenterContent" lg={6} md={6} xl={3}>
              <div className="footerAppStore">
                <img className="playstore" src={playstore} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  <button class="btn footerButton text-uppercase">
                    coming soon
                  </button>
                  <button class="btn footerButton text-uppercase">
                    coming soon
                  </button>
                </div>
              </div>
            </Col>
            <Col 
              className="p-3 footerCenterContent text-uppercase"
              lg={6} md={6} xl={3}
              style={{ color: "#ACACAC" }}
            >
              <h5>Navigation</h5>
              <h5><Link to="/">Home</Link></h5>
              <h5><Link to="/Aboutus"> about talentverse </Link></h5>
              <h5><a href="https://talentverse.in/blogs/">blog & Stories</a></h5>
              <h5><Link to="/features">features</Link></h5>
              <h5><span onClick={handleShowModal} style={{cursor:'pointer'}}>become an artist</span></h5>
            </Col>
            <Col 
              className="p-3 footerCenterContent text-uppercase"
              lg={6} md={6} xl={3}
              style={{ color: "#ACACAC" }}
            >
              <Row>
                <Col  lg={12}>
                  <h5>Legal</h5>
                  <h5><Link to="/TermsandConditions" style={{textDecoration:"none"}}>Terms and conditions</Link></h5>
                  <h5><Link to="/PrivacyPolicy" style={{textDecoration:"none"}}> privacy policy</Link></h5>
                </Col>
              </Row>
              <Row>
                <Col  className="mt-5">
                  <h5>Contact us</h5>
                  <h5 className="text-lowercase text-decoration-underline">
                    <a href="mailto:hello@talentverse.in">
                      hello@talentverse.in
                    </a>
                  </h5>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              className="text-center mt-5 mb-4 footerCopyright"
              lg={12}
              style={{ color: "#9B9B9A" }}
            >
              ©️ 2023 talentverse | All rights reserved
              <span>
                Powered by <a href="https://www.iugale.com/" style={{color:"#9B9B9A"}} className="text-decoration-underline">IUGALE</a>
              </span>
            </Col>
          </Row>
          {/* <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__TermsandConditions_down_footer"> 
                <img className="" src={Footerimg_Down} style={{width:"100%"}}/>
                </div>
            </Col>
          </Row> */}
        </Container>
      </div>
      <SubscribeModal showModal={showModal} handleClose={handleCloseModal} />
    </>
  );
};

export default Footer;
