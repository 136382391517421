import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./PrivacyPolicy.css";
import Footer from "../Footer/Footer";
import Navigation from "../Home/Navigation/Navigation";
import imgbgpvtright from "../../IMG/innovative/XMLID_1_.png"
import imgbgpvtleft from "../../IMG/innovative/XMLID_12.png"
import XMLID_14_tc from "../../IMG/XMLID_14.png";
import Frame from "../../IMG/Frame.png";

const PrivacyPolicy = () => {
  return (
    <div>
      <Container fluid className="main p-relative" style={{marginTop:"150px"}}>
        <Navigation  />
        <Container className="py-4">
          <Row>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              lg={12}
              xs={12}
              style={{ border: "0px solid red",zIndex:"11"}}
              className="text-center"
            >
              <h1 className="headingnew">PRIVACY POLICY</h1>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }} className="main">
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left "
            >
              <p>
                Talentverse ("us", "we", or "our") operates the Talentverse
                website (the "Service"). This page informs you of our policies
                regarding the collection, use, and disclosure of Personal
                Information when you use our Service. We will not use or share
                your information with anyone except as described in this Privacy
                Policy.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                We use your Personal Information for providing and improving the
                Service. By using the Service, you agree to the collection and
                use of information in accordance with this policy.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p className="py-2">Information Collection and Use</p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you. Personally identifiable information may
                include, but is not limited to, your name, email address, phone
                number, postal address, and other information that you
                voluntarily provide to us.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                We may also collect information about how you use the Service,
                such as the pages you visit, the time and date of your visit,
                the amount of time spent on those pages, and other statistics.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p className="py-2"> Log Data</p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                {" "}
                We may also collect information that your browser sends whenever
                you visit our Service ("Log Data"). This Log Data may include
                information such as your computer's Internet Protocol ("IP")
                address, browser type, browser version, the pages of our Service
                that you visit, the time and date of your visit, the time spent
                on those pages, and other statistics.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p className="py-2">Cookies</p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                Cookies are files with small amounts of data, which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a web site and stored on your computer's hard drive.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                We use cookies to collect information in order to improve our
                Service for you. You can instruct your browser to refuse all
                cookies or to indicate when a cookie is being sent. However, if
                you do not accept cookies, you may not be able to use some
                portions of our Service.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p className="py-2"> Service Providers</p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                We may employ third-party companies and individuals to
                facilitate our Service, to provide the Service on our behalf, to
                perform Service-related services, or to assist us in analyzing
                how our Service is used.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                These third parties may have access to your Personal Information
                only to perform these tasks on our behalf and are obligated not
                to disclose or use it for any other purpose.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p className="py-2">Security</p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                The security of your Personal Information is important to us,
                but remember that no method of transmission over the Internet or
                method of electronic storage is 100% secure. While we strive to
                use commercially acceptable means to protect your Personal
                Information, we cannot guarantee its absolute security.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red" ,zIndex:"11"}}
              className="text-left"
            >
              <p className="py-2">Links to Other Sites </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                Our Service may contain links to other sites that are not
                operated by us. If you click on a third-party link, you will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy of every site you visit.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third-party sites
                or services.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p className="py-2">Children's Privacy</p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                Our Service does not address anyone under the age of 13
                ("Children").
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue", }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                We do not knowingly collect personally identifiable information
                from children under 13. If you are a parent or guardian and you
                are aware that your child has provided us with Personal
                Information, please contact us. If we become aware that we have
                collected Personal Information from a child under age 13 without
                verification of parental consent, we take steps to remove that
                information from our servers.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p className="py-2">Changes to This Privacy Policy</p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red" ,zIndex:"11"}}
              className="text-left"
            >
              <p className="py-2">Contact Us</p>
            </Col>
          </Row>
          <Row style={{ border: "0px solid blue" }}>
            <Col data-aos="fade-up"  data-aos-duration="3000"
              xxl={{ span: 12, offset: 0 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              style={{ border: "0px solid red",zIndex:"11" }}
              className="text-left"
            >
              <p>
                If you have any questions about this Privacy Policy, please
                contact us at <a className="text-lowercase text-decoration-underline text-white" href="mailto:hello@talentverse.in">hello@talentverse.in</a>
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__PrivacyPolicy_top opacity-50"> 
                <img className="" src={imgbgpvtright} style={{width:"100%"}}/>
                </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__PrivacyPolicy_top_menu d-none d-lg-block d-xl-block opacity-50">
                <img className="" src={Frame} style={{ width: "100%" }} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__PrivacyPolicy_down opacity-50"> 
                <img className="" src={imgbgpvtleft} style={{width:"100%"}}/>
                </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__PrivacyPolicy_down_Second opacity-50"> 
                <img className="" src={imgbgpvtleft} style={{width:"100%"}}/>
                </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__PrivacyPolicy_down_Three_mobile opacity-50"> 
                <img className="" src={imgbgpvtleft} style={{width:"100%"}}/>
                </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
                <div className="tp-feature__PrivacyPolicy_top_mobile opacity-50"> 
                <img className="" src={XMLID_14_tc} style={{width:"100%"}}/>
                </div>
            </Col>
            </Row>
            <Row>
            <Col md={12} lg={5}>
                <div className="tp-feature__PrivacyPolicy_top_mobile_second opacity-50"> 
                <img className="" src={XMLID_14_tc} style={{width:"100%"}}/>
                </div>
            </Col>
            </Row>
            <Row>
            <Col md={12} lg={5}>
                <div className="tp-feature__PrivacyPolicy_top_mobile_Three opacity-50"> 
                <img className="" src={XMLID_14_tc} style={{width:"100%"}}/>
                </div>
            </Col>
            </Row>
        </Container>
        <Footer />
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
