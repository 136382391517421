import React from 'react'
import "./About.css"

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import bgimgtop from "../../../IMG/Aboutus/background/XMLID_13_ About.png"
import { Container } from 'react-bootstrap';
import Slidercomponet from './Slidercomponet';
const About = () => {
    return (
        <>
            <Container className='p-relative' style={{marginTop:"150px"}}>
                <Row className='displaytab'>
                    <Col data-aos="fade-up"  data-aos-duration="3000" className='offset-lg-3' lg={10}>
                        <h1 className='Aboutusclass'>About Us</h1>
                    </Col>
                </Row>
                <Row className='my-5 displaytab'>
                    <Col>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col className='tabchangeclass' lg={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item data-aos="fade-up"  data-aos-duration="3000">
                                            <Nav.Link className='tabclasss text-uppercase' eventKey="first">-    Brief About Talentverse</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item data-aos="fade-up"  data-aos-duration="3000">
                                            <Nav.Link className='tabclasss text-uppercase' eventKey="second">-    What Inspired Us?</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item data-aos="fade-up"  data-aos-duration="3000">
                                            <Nav.Link className='tabclasss text-uppercase' eventKey="third">-    Our Mission</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item data-aos="fade-up"  data-aos-duration="3000">
                                            <Nav.Link className='tabclasss text-uppercase' eventKey="four">-   Our Vision</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col lg={9} md={6}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <p data-aos="fade-up"  data-aos-duration="3000" className='tabcontant'>
                                                At Talentverse, we believe that everyone deserves a chance to showcase their talents, and connect with like-minded individuals. We are designing a platform to bring together a diverse community of Artists, Creators, and Learners, providing them with the tools, and resources they need to thrive. Whether you're a seasoned professional or just starting out, Talentverse is here to support you on your journey.  </p>
                                                <p data-aos="fade-up"  data-aos-duration="3000" className='tabcontant'>

                                                We strive to create a platform that benefits both our users, and artists, placing our community of creators, and users at the heart of our mission.
                                                </p>
                                          
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <p data-aos="fade-up"  data-aos-duration="3000" className='tabcontant'>
                                                Our journey began with a simple observation: there are countless talented individuals out there who lack the resources and opportunities they need to succeed. We saw this as a problem that needed solving, and decided to create a platform that could bring together people from all walks of life, united by their passion for creativity and learning. Our goal is to empower individuals to take control of their own destinies and build meaningful, fulfilling careers doing what they love.
                                            </p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <p data-aos="fade-up"  data-aos-duration="3000" className='tabcontant'>
                                                Talentverse's mission is to create a user-centric platform that inspires, empowers, and connects, talents and learners from all backgrounds, enabling them to discover their potential, collaborate, learn, and grow.
                                               
                                            </p>
                                            <p className='tabcontant'>
                                            We strive to foster a supportive, and inclusive environment that enables everyone to benefit, and to provide opportunities for personal, and professional development, while promoting positive change in the world.
                                            </p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="four">
                                            <p data-aos="fade-up"  data-aos-duration="3000" className='tabcontant'>
                                                To be the North Star for all forms of Talent.
                                            </p>
                                            <p className='tabcontant'>
                                            Talentverse aims to be a guiding light for everyone to discover, and showcase their talent, and create a world where anyone can pursue their dreams, and achieve their full potential, regardless of their background, or circumstances. We believe that by providing a supportive, inclusive platform for talents and learners, we can help to unlock the power of human potential, and drive positive change in the world.
                                            </p>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={5}>
                        <div className="tp-feature__top-About-right imgbgblur">
                            <img className="" src={bgimgtop} style={{ width: "100%"  }} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Slidercomponet />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default About