import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Index from "./Component/Home/Index";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Aboutus from "./Component/AboutUs/Aboutus";
import TermsandConditions from "./Component/TermsandConditions/TermsandConditions";
import PrivacyPolicy from "./Component/PrivacyPolicy/PrivacyPolicy";
import Innovative from "./Component/Innovative/Innovative ";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "./Component/ScrollToTop/ScrollToTop";
function App() {
  useEffect(() => {
    AOS.init();
    
  }, []);
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route
            path="/TermsandConditions"
            element={<TermsandConditions />}
          />
          <Route
            path="/Aboutus"
            element={<Aboutus />}
          />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/features" element={<Innovative />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
