import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./Whatpeople.css"
import imgpic from "../../../IMG/Aboutus/Lalitha.png"
import bgimgwhatpeoplesay from "../../../IMG/Aboutus/background/XMLID_13_ 4About.png"
import Tptheidea_Second from "../../../IMG/Aboutus/background/XMLID_13_ 3About.png"
const Whatpeoples = () => {
    return (
        <>
            <Container className='p-relative mt-5' style={{marginBottom:"100px"}}>
                <Row className='marginDesktop' style={{marginTop:"100px"}}>
                    <Col data-aos="fade-up"  data-aos-duration="3000" lg={12}>
                        <h1 className='whatpeopleheading'>what people say</h1>
                    </Col>
                    <Col data-aos="fade-up"  data-aos-duration="3000" className='text-center' lg={12}>
                        <img className='imgpic' src={imgpic} />
                        <p className='name'>Lalitha K G</p>
                        <span className='nickname'>Mandala Artist</span>
                    </Col>
                    <Col data-aos="fade-up"  data-aos-duration="3000" className='paragraph mt-2 text-center' lg={12} >
                        <p>
                        Talentverse, and its people are the reason why young artists like us see the light at the end of the tunnel. It's an opportunity for people to witness hidden talents and skills, promote, and appreciate the efforts the artists put into their work.
                        </p>
                        {/* <p >
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                        </p>
                        <p>

                            when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p> */}
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={5}>
                        <div className="tp-feature__Whatpeoplesay imgbgblur"> 
                        <img className="" src={bgimgwhatpeoplesay} style={{width:"100%"}}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={5}>
                        <div className="tp-feature__Tptheidea_Second imgbgblur"> 
                        <img className="" src={Tptheidea_Second} style={{width:"100%"}}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={5}>
                        <div className="tp-feature__Tptheidea_last imgbgblur"> 
                        <img className="" src={Tptheidea_Second} style={{width:"100%"}}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Whatpeoples