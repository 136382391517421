import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from "react-slick";
import Rectangle48 from "../../../IMG/slider/Rectangle 48.png"
import Rectangle49 from "../../../IMG/slider/Rectangle 49.png"
import Rectangle50 from "../../../IMG/slider/Rectangle 50.png"
import Rectangle51 from "../../../IMG/slider/Rectangle 51.png"
import Rectangle52 from "../../../IMG/slider/Rectangle 52.png"
import Rectangle53 from "../../../IMG/slider/Rectangle 55.png"
//------------------------------//
import Rectangle490 from "../../../IMG/slider/Rectangle490.png"
import Rectangle500 from "../../../IMG/slider/Rectangle500.png"
import Rectangle522 from "../../../IMG/slider/Rectangle522.png"
import Rectangle551 from "../../../IMG/slider/Rectangle551.png"
//------------------------------//
import Rectangle481 from "../../../IMG/slider/Rectangles 48.png"
import Rectangle491 from "../../../IMG/slider/Rectangles 49.png"
import Rectangle501 from "../../../IMG/slider/Rectangles 50.png"
import Rectangle511 from "../../../IMG/slider/Rectangles 51.png"
import Rectangle521 from "../../../IMG/slider/Rectangles 52202.png"
import Rectangle531 from "../../../IMG/slider/Rectangles 53.png"
//----------------------------------//
import iphone13pro from "../../../IMG/Frame11.png";
import Ellipse0 from "../../../IMG/slider/Ellipse0.png"
//---//
import backgroundsliderimg from "../../../IMG/slider/Ellipse0.png"
import { Col, Container, Row } from 'react-bootstrap';
import "./Slideranimation.css"
const Slideranimation = () => {
  var settings = {

    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    cssEase: 'linear',
    pauseOnHover: false,
    arrows: false,

    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,

        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      }

    ]

  };
  var settingss = {

    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    rtl: true,
    cssEase: 'linear',
    pauseOnHover: false,
    arrows: false,

    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,

        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      }

    ]

  };
  return (
    <div className='p-relative' data-aos="fade-up"  data-aos-duration="3000">
      <Container className='margincon upperIndex'>

        <Row>
          <Col className="third order-md-first upperIndex" md={12} lg={12}>
            <Row>
              <Col>
                <h6 className="fontFeatures mt-3">Talentverse Advantage </h6>
                <h1 className="VersatileTools mt-3">Why Choose Talentverse?</h1>
                <p className="whychoosecontent">
                  Talentverse is the ultimate platform for all forms of talents to share their work with the world. With our cutting-edge technology, supportive community, and commitment to unlocking your full potential, you'll have everything you need to succeed.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className='backgroundslider backgroundslider upperIndex'>

        <div className='relativeclass upperIndex'>

          <div className='sliderrelative'>
            <Slider {...settings}>
              <Helper imgsrc={Rectangle48} />
              <Helper imgsrc={Rectangle49} />
              <Helper imgsrc={Rectangle50} />
              <Helper imgsrc={Rectangle51} />
              <Helper imgsrc={Rectangle52} />
              <Helper imgsrc={Rectangle53} />
            </Slider>
            <Slider {...settingss}>
              <Helper imgsrc={Rectangle490} />
              <Helper imgsrc={Rectangle500} />
              <Helper imgsrc={Rectangle522} />
              <Helper imgsrc={Rectangle551} />
              <Helper imgsrc={Rectangle52} />
              <Helper imgsrc={Rectangle53} />
            </Slider>
            <Slider {...settings}>
              <Helper imgsrc={Rectangle481} />
              <Helper imgsrc={Rectangle491} />
              <Helper imgsrc={Rectangle501} />
              <Helper imgsrc={Rectangle511} />
              <Helper imgsrc={Rectangle521} />
              <Helper imgsrc={Rectangle531} />
            </Slider>
          </div>
          <div className='mobileonslider'>
            <img className="iphone" src={iphone13pro} />
          </div>
        </div>
      </div>
      <Row>
        <Col md={12} lg={5}>
          <div className="tp-feature__bottom-slider">
            <img className="bgpink" src={Ellipse0} />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Slideranimation
function Helper(props) {
  return (
    <>
      <Col >
        <img src={props.imgsrc} className="imgsrcclass"></img>
      </Col>
    </>
  )

}