import React, { useState } from "react";
import "./Innovative.css";
import { Col, Row } from "react-bootstrap";
// import MusicalImg from "../../IMG/innovative/emotional-lady-red-headphones-singing-microphone-bright-beautiful-girl-white-blouse-jeans-with-black-belt-posing-transformed 1.png";
import MusicalImg from "../../IMG/innovative/musician.jpg";
// import Dancer from "../../IMG/innovative/young-guitarist-plays-electric-guitar-with-bright-emotions-isolated-white-wall-transformed 1.png";
import Dancer from "../../IMG/innovative/Dancer.jpg";
// import Boxer from "../../IMG/innovative/inspired-funny-emotions-professional-boxer-isolated-white-studio-background-excitement-game-human-emotions-facial-expression-passion-with-sport-concept-aU_wxzJim-transformed 1.png";
import Boxer from "../../IMG/innovative/boxer.jpg";
import BackgroundRight from "../../IMG/innovative/XMLID_1_.png";
import BackgroundLeft from "../../IMG/innovative/XMLID_12.png";
import Rectangle2 from "../../IMG/innovative/Rectangle 74.png";
import Rectangle1 from "../../IMG/innovative/Rectangle 73.png";
import ledis from "../../IMG/innovative/young-classical-dancer-isolated-white-transformed 1.png";
import musicLeft from "../../IMG/innovative/musicLeft.png";
import musicRight from "../../IMG/innovative/musicRight.png";
import dancer1 from "../../IMG/innovative/dancer1.png";
import dancer2 from "../../IMG/innovative/dancer2.png";
import dancer3 from "../../IMG/innovative/dancer3.png";
import boxer1 from "../../IMG/innovative/boxer1.png";
import boxer2 from "../../IMG/innovative/boxer2.png";
import SubscribeModal from "../Modal/SubscribeModal";
import Frame from "../../IMG/Frame.png";

const Musical = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      {/* <div className="headerBackgroundImage d-none d-lg-block d-xl-block opacity-50" style={{maxWidth:"200px"}}>
        <img src={Frame}  />
      </div> */}
      <div className="desktop" style={{ marginTop: "150px" }}>
        <img src={Rectangle1} className="position-absolute w-100 rectangle1" />
        <Row
          className="container mx-auto text-white justify-content-end align-content-start position-relative innovativeFirstSection"
          data-aos="fade-up"
          data-aos-duration="3000"
          style={{ zIndex: "1000000" }}
        >
          <img src={ledis} className="position-absolute ladis" />
          <Col xl={10} lg={10}>
            <h1>Innovative Features to Empower </h1>
            <h1>Talentversers Everywhere</h1>
          </Col>
          <Col style={{ zIndex: 1 }} xl={9} lg={9}>
            <p>
              Talentverse is more than just a platform, it's a community of
              artists, creators, and passionate individuals who share their
              talents, knowledge, and creativity with the world. As a
              Talentverser, you'll be part of a thriving community of
              like-minded individuals who inspire and support each other. From
              discovering new talents to connecting with fellow Talentversers,
              there's something for everyone on our platform. Join us today and
              become a part of the Talentverse community!
            </p>
            <button onClick={handleShowModal}>Become a Talentverser</button>
          </Col>
        </Row>

        <div
          className="position-relative"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <img
            src={BackgroundRight}
            className="position-absolute end-0 w-25 opacity-50"
          />
          <Row className="container mx-auto text-white position-relative z-1 musicalSection align-items-center">
            <Col lg={6} className="musicalLeft">
              <h1 id="title">musician</h1>
              <p id="desc">
                As a musician, we understand the importance of being able to
                share your art with the world. Whether you're a singer, a
                songwriter, or a performer, we believe that your talent deserves
                to be heard. At Talentverse, we provide you with the platform
                and the tools to showcase your music to a wider audience.
                <br />
                <br />
                Connect with fellow Talentversers and collaborate on new
                projects, or simply share your music with fans who appreciate
                your craft.
              </p>
              <button id="btn" onClick={handleShowModal}>
                Join Waitlist
              </button>
            </Col>
            <Col lg={6} class="musicalRight" style={{ textAlign: "center" }}>
              <img src={MusicalImg} id="image" width={350} />
            </Col>
          </Row>
        </div>

        <div
          className="position-relative"
          style={{ marginTop: "200px" }}
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <img
            src={BackgroundLeft}
            className="position-absolute  w-25 opacity-50"
          />
          <img src={Rectangle2} className="position-absolute w-100 top-50" />
          <Row className="container mx-auto text-white position-relative z-1 musicalSection align-items-center">
            <Col lg={5} class="musicalRight" style={{ minWidth: "40%" }}>
              <img src={Dancer} width={445} />
            </Col>
            <Col lg={7} className="musicalLeft">
              <h1>Dancer</h1>
              <p>
                Dancing is more than just movement - it's a way to express
                yourself and connect with others. As a dancer, we know how
                important it is to have a supportive community that encourages
                you to grow and learn. At Talentverse, we provide you with a
                space to connect with other dancers, choreographers, and
                performers.
                <br />
                <br />
                Share your talents, collaborate on new projects, and learn from
                others who share your passion.
              </p>
              <button onClick={handleShowModal}>Join Waitlist</button>
            </Col>
          </Row>
        </div>

        <div
          className="position-relative"
          style={{ marginTop: "120px" }}
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <img
            src={BackgroundRight}
            className="position-absolute end-0 w-25 opacity-50"
          />
          <Row className="container mx-auto text-white position-relative z-1 musicalSection align-items-center">
            <Col lg={6} className="musicalLeft">
              <h1>boxer</h1>
              <p>
                As a boxer or fitness instructor, we know that your passion for
                fitness goes beyond just working out. You want to inspire others
                to be their best selves and to achieve their fitness goals. At
                Talentverse, we provide you with a platform to connect with
                others who share your passion for fitness.
                <br />
                <br />
                Whether you're looking to share your workout routines, connect
                with new clients, or collaborate on new projects, Talentverse is
                the perfect place to grow your fitness brand and reach new
                audiences.
              </p>
              <button onClick={handleShowModal}>Join Waitlist</button>
            </Col>
            <Col lg={6} class="musicalRight" style={{ textAlign: "center" }}>
              <img src={Boxer} width={400} />
            </Col>
          </Row>
        </div>
      </div>

      <div className="mobile" style={{ marginTop: "150px" }}>
        <div
          className="py-4 text-white"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <h1 className="text-center title">
            Innovative Features to Empower Talentversers Everywhere
          </h1>
          <img src={ledis} className="w-100 moveTop" />
          <p class="desc moveTop">
            Talentverse is more than just a platform, it's a community of
            artists, creators, and passionate individuals who share their
            talents, knowledge, and creativity with the world. As a
            Talentverser, you'll be part of a thriving community of like-minded
            individuals who inspire and support each other. From discovering new
            talents to connecting with fellow Talentversers, there's something
            for everyone on our platform. Join us today and become a part of the
            Talentverse community!
          </p>
          <button class="jopinBtn moveTop" onClick={handleShowModal}>
            Become a Talentverser
          </button>
        </div>

        <div
          className="py-4 text-white position-relative moveTop"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <img
            src={musicLeft}
            className="position-absolute backgroundLeft opacity-50"
          />
          <img
            src={musicRight}
            className="position-absolute backgroundRight1 opacity-50"
          />
          <h1 className="text-center title">musician</h1>
          <img
            src={MusicalImg}
            style={{ textAlign: "center" }}
            id="MusicalImg"
            width={285}
          />
          <p class="desc mt-4">
            As a musician, we understand the importance of being able to share
            your art with the world. Whether you're a singer, a songwriter, or a
            performer, we believe that your talent deserves to be heard. At
            Talentverse, we provide you with the platform and the tools to
            showcase your music to a wider audience.
            <br />
            <br />
            Connect with fellow Talentversers and collaborate on new projects,
            or simply share your music with fans who appreciate your craft.
          </p>
          <div className="w-100" style={{ textAlign: "left" }}>
            <button class="jopinBtn" onClick={handleShowModal}>
              Join Waitlist
            </button>
          </div>
        </div>

        <div
          className="py-4 text-white position-relative moveTop"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <img
            src={dancer1}
            className="position-absolute backgroundLeft opacity-50"
          />
          <img
            src={dancer2}
            className="position-absolute backgroundRight1 top-0 opacity-50"
          />
          <img
            src={dancer3}
            className="position-absolute backgroundRight1 opacity-50"
          />
          <h1 className="text-center title">Dancer</h1>
          <img src={Dancer} width={285} />
          <p class="desc mt-4">
            Dancing is more than just movement - it's a way to express yourself
            and connect with others. As a dancer, we know how important it is to
            have a supportive community that encourages you to grow and learn.
            At Talentverse, we provide you with a space to connect with other
            dancers, choreographers, and performers.
            <br />
            <br />
            Share your talents, collaborate on new projects, and learn from
            others who share your passion.
          </p>
          <div className="w-100" style={{ textAlign: "left" }}>
            <button class="jopinBtn" onClick={handleShowModal}>
              Join Waitlist
            </button>
          </div>
        </div>

        <div
          className="py-4 text-white position-relative moveTop"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <img
            src={boxer1}
            className="position-absolute backgroundLeft opacity-50"
          />
          <img
            src={boxer2}
            className="position-absolute backgroundRight1 top-50 opacity-50"
          />
          <h1 className="text-center title">boxer</h1>
          <img src={Boxer} width={285} />
          <p class="desc mt-4">
            As a boxer or fitness instructor, we know that your passion for
            fitness goes beyond just working out. You want to inspire others to
            be their best selves and to achieve their fitness goals. At
            Talentverse, we provide you with a platform to connect with others
            who share your passion for fitness.
            <br />
            <br />
            Whether you're looking to share your workout routines, connect with
            new clients, or collaborate on new projects, Talentverse is the
            perfect place to grow your fitness brand and reach new audiences.
          </p>
          <div className="w-100" style={{ textAlign: "left" }}>
            <button class="jopinBtn" onClick={handleShowModal}>
              Join Waitlist
            </button>
          </div>
        </div>
      </div>
      <SubscribeModal showModal={showModal} handleClose={handleCloseModal} />
    </>
  );
};

export default Musical;
