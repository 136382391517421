import React, { useEffect, useState } from "react";

import "./Home.css";
import Navigation from "../Navigation/Navigation";
import HomeScreentext from "./HomeScreentext";

const Home = () => {

  return (
    <>
      <div className="bg_Img">
        <Navigation />
        <HomeScreentext />
      </div>
    </>
  );
};

export default Home;
