import React, { useState } from "react";
import "./Stories.css";
import { Col, Container, Row } from "react-bootstrap";
import StoriesSlider from "./StoriesSlider/StoriesSlider";
import BackgroundRight from "../../../IMG/innovative/XMLID_1_.png";
import BackgroundLeft from "../../../IMG/innovative/XMLID_13.png";
import SubscribeModal from "../../Modal/SubscribeModal";

const Stories = ({fromPage}) => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className={`pt-5 ${fromPage===`innovative`  ? `marginconSliderInnovative position-relative` : 'marginconSlider' }`}>
        <Container className="text-white">
        {fromPage===`innovative` ? (
              <div>
                <img
                  className="tp-feature__bottom-shape__innovative_page_left d-none d-lg-block d-xl-block opacity-50"
                  src={BackgroundLeft}
                />
              </div>
            ) :null}
          <Row className="align-items-center">
            <Col data-aos="fade-up"  data-aos-duration="3000" lg={6}>
              <div className="stories">
                <h3 className="stories-subTitle">Stories</h3>
                <h1 className="stories-title">Talent Journey</h1>
                <p className="stories-desc" style={{color: "#9B9B9A"}}>
                  Follow the inspiring journeys of fellow talents as they share
                  their triumphs, and struggles. From emerging artists to
                  industry veterans, Talentverse brings the talent community
                  together to foster growth, collaboration, and positive change
                  in society.
                </p>
                <button className="d-none d-lg-block d-xl-block" onClick={handleShowModal}>Join Waitlist</button>
              </div>
            </Col>
            <Col data-aos="fade-up"  data-aos-duration="3000" lg={6}>
            <StoriesSlider />
            </Col>
           
          </Row>
          {fromPage===`innovative`  ? (
              <div className="d-none d-lg-block d-xl-block">
                <img
                  className="tp-feature__bottom-shape__innovative_page_right opacity-50"
                  src={BackgroundRight}
                />
              </div>
              
            ) :null}
        </Container>
      </div>
      <SubscribeModal showModal={showModal} handleClose={handleCloseModal} />
    </>
  );
};

export default Stories;
