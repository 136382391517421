import React from 'react'
import Fullimg from "../../../IMG/Aboutus/Rectangle6.png"
import Fullimg_mobile from "../../../IMG/Aboutus/Rectangle6mobile.png"
import "./Imgfull.css"
const Imgfull = () => {
  return (
    <div data-aos="fade-up"  data-aos-duration="3000" className='p-relative' style={{zIndex:"1000000"}}>
      <img className='Fullimg bigscreenimg' src={Fullimg} />
      <img className='Fullimg mobileimg' src={Fullimg_mobile} />
    </div>
  )
}

export default Imgfull