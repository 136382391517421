import React, { useEffect } from "react";
import "./Dimensions.css";
import Container from "react-bootstrap/esm/Container";
import Rectangle4 from "../../../IMG/Rectangle4.png";
import Rectangle6 from "../../../IMG/Rectangle6.png";
import Rectangle7 from "../../../IMG/Rectangle7.png";
import Group from "../../../IMG/Group.png";
import Ellipse from "../../../IMG/Ellipse.png";
import XMLID_13 from "../../../IMG/XMLID_13.png";
import XMLID_14 from "../../../IMG/XMLID_14.png";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Dimensions = () => {
  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.saveStyles(".pSection");
      ScrollTrigger.matchMedia({
        "(min-width: 800px)": function () {
          //document.body.style.backgroundColor = "pink";
          gsap
            .timeline({
              scrollTrigger: {
                trigger: ".pSection",
                yPercent: -100,
                start: "top top",
                // end: 'bottom top',
                end: "+=500",
                // ease: "expo",
                scrub: true,
              },
            })
            .fromTo(".pImg_1", { y: -300 }, { y: -100, opacity:0 }, 0)
            .fromTo(".pImg_2", { y: -330 }, { y: -100, opacity:0 }, 0)
            .fromTo(".pImg_3", { y: -360 }, { y: -100 }, 0)
            .fromTo(".people1", { y: 1000 }, { y: 400 }, 0)
            .fromTo(".pImg_top", { y: 100 }, { y: -400 }, 0)
            .fromTo(".text1", { y: 300 }, { y: 100 }, 0)
            .fromTo(".text2", { y: 320 }, { y: 120 }, 0);
          //  .fromTo(".text3", {y:800},{y:200}, 0)
          // .fromTo(".text4", {y:820},{y:200}, 0)
        },

        // tab
        // "(max-width: 799px)": function () {
        //   gsap
        //     .timeline({
        //       scrollTrigger: {
        //         trigger: ".pSection",
        //         yPercent: -100,
        //         start: "top top",
        //         //end: 'bottom top',
        //         end: "+=200",
        //         // ease: "expo",
        //         scrub: true,
        //       },
        //     })
        //     // .fromTo('.pImg_1', {y:70},{y:-100}, 0)
        //     // .fromTo('.pImg_2', {y:85},{y:-100}, 0)
        //     // .fromTo('.pImg_3', {y:100},{y:-100}, 0)
        //     .fromTo(".pImg_1", { y: 70 }, { y: -100 }, 0)
        //     .fromTo(".pImg_2", { y: 85 }, { y: -100 }, 0)
        //     .fromTo(".pImg_3", { y: 100 }, { y: -100 }, 0)
        //     .fromTo(".people1", { y: 400 }, { y: 100 }, 0)

        //     .fromTo(".pImg_top", { y: 100 }, { y: -100 }, 0)
        //     .fromTo(".text1", { y: 200 }, { y: -200 }, 0)
        //     .fromTo(".text2", { y: 220 }, { y: -220 }, 0);
        //   // .fromTo(".text3", {y:400},{y:100}, 0)
        //   //.fromTo(".text4", {y:400},{y:100}, 0)
        // },

        "(min-width: 280px) and (max-width: 320px)": function () {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: ".blacklineargradient",
                yPercent: -100,
                start: "top top",
                end: "+=400",
                // ease: "expo",
                scrub: true,
              },
            })
            .fromTo(".pImg_1", { y: -50 }, { y: -40, opacity:0 }, 0)
            .fromTo(".pImg_2", { y: -40 }, { y: -40, opacity:0 }, 0)
            .fromTo(".pImg_3", { y: -30 }, { y: -40 }, 0)
            .fromTo(".people1", { y: 270 }, { y: 100 }, 0)
            .fromTo(".pImg_top", { y: 100 }, { y: -100 }, 0)
            .fromTo(".text1", { y: 200 }, { y: -100 }, 0)
            .fromTo(".text2", { y: 220 }, { y: -100 }, 0);
        },

        "(min-width: 321px) and (max-width: 767px)": function () {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: ".blacklineargradient",
                yPercent: -100,
                start: "top top",
                end: "+=500",
                // ease: "expo",
                scrub: true,
              },
            })
            .fromTo(".pImg_1", { y: -50 }, { y: -100, opacity:0 }, 0)
            .fromTo(".pImg_2", { y: -40 }, { y: -100, opacity:0 }, 0)
            .fromTo(".pImg_3", { y: -30 }, { y: -100 }, 0)
            .fromTo(".people1", { y: 370 }, { y: 100 }, 0)
            .fromTo(".pImg_top", { y: 100 }, { y: -100 }, 0)
            .fromTo(".text1", { y: 200 }, { y: -100 }, 0)
            .fromTo(".text2", { y: 220 }, { y: -100 }, 0);
        },

        //tab
        "(min-width: 768px) and (max-width: 1023px)":function () {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: ".pSection",
                yPercent: -100,
                start: "top top",
                end: "+=500",
                // ease: "expo",
                scrub: true,
              },
            })
            .fromTo(".pImg_1", { y: -455 }, { y: -280, opacity:0 }, 0)
            .fromTo(".pImg_2", { y: -420 }, { y: -280, opacity:0 }, 0)
            .fromTo(".pImg_3", { y: -390 }, { y: -280 }, 0)
            .fromTo(".people1", { y: 430 }, { y: 100 }, 0)
            .fromTo(".pImg_top", { y: 100 }, { y: -100 }, 0)
            .fromTo(".text1", { y: 200 }, { y: -100 }, 0)
            .fromTo(".text2", { y: 220 }, { y: -100 }, 0);
        },
        //mobile screen
       

        // all
        all: function () {
          // ScrollTriggers created here aren't associated with a particular media query,
          // so they persist.
        },
      });
    }, [100]);
  }, []);

  return (
    <>
      <section className="pSection bgdimension p-relative">
        <div className="container1 text-center">
          <div className="pImg_top">
            <img className="top_img" src={Ellipse} />
          </div>
          <div className="text1">
            <h1>Enter a New Dimension of</h1>
          </div>
          <div className="text2">
            <h1 className="gradient-text">Creativity</h1>
          </div>
          <div className="people1">
            <img className="pImage" src={Group} />
          </div>
          <div className="pImg_1">
            <img className="Rectangle_1" src={Rectangle4} />
          </div>
          <div className="pImg_2">
            <img className="Rectangle_2" src={Rectangle6} />
          </div>
          <div className="pImg_3">
            <img className="Rectangle_3" src={Rectangle7} />
          </div>
        </div>
      </section>
      <div className="bgdimension1 p-relative">
        <Container>
          <Row>
            <Col md={12} className="mt-5 text-center text_index">
              <div className="text3">
                <h1>Breaking the Barriers</h1>
              </div>
              <div className="text4">
                <p>
                  Explore New Possibilities, Ignite Your Creativity and Showcase
                  Your Unique Talents to the World with Talentverse - Turn Your
                  Passion into a Profession!
                </p>
              </div>
              <br></br>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col md={12} lg={5}>
            <div className="tp-feature__bottom-dimensions imgbgblur">
              <img className="" src={XMLID_13} style={{ width: "100%" }} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={5}>
            <div className="tp-feature__bottom-dimensions-right imgbgblur">
              <img className="" src={XMLID_14} style={{ width: "100%" }} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dimensions;
