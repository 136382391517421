import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import Edgar from '../../../IMG/Aboutus/Edgar Degas.jpg';
import Thomas from '../../../IMG/Aboutus/Thomas Merton.jpg';
import Pablo from '../../../IMG/Aboutus/Pablo Picasso.jpg';
import Road from '../../../IMG/Aboutus/Rod Steiger.jpg';
import Abraham from '../../../IMG/Aboutus/Abraham Lincoln.jpg';
import colanimg from "../../../IMG/Aboutus/colan.png"
import "./Slidersecond.css"

const Slidersecond = () => {
    const quote = [
        {
            author: "Pablo Picasso",
            text: "Every child is an artist, the problem is how to remain an artist once we grow up.",
            authorImg: Pablo,
            authorName: "Edgar Degas",
            authorText: "Art is not what you see, but what you make others see.",
        },
        {
            author: "Rod Steiger",
            text: "The most important thing is to be whatever you are without shame.",
            authorImg: Road,
            authorName: "Edgar Degas",
            authorText: "Art is not what you see, but what you make others see.",
        },
        {
            author: "Edgar Degas",
            text: "Art is not what you see, but what you make others see.",
            authorImg: Edgar,
            authorName: "Edgar Degas",
            authorText: "Art is not what you see, but what you make others see.",
        },
        {
            author: " Thomas Merton",
            text: "Art enables us to find ourselves and lose ourselves at the same time.",
            authorImg: Thomas,
            authorName: "Edgar Degas",
            authorText: "Art is not what you see, but what you make others see.",
        },
        {
            author: "Abraham Lincoln",
            text: "The best way to predict your future is to create it.",
            authorImg: Abraham,
            authorName: "Edgar Degas",
            authorText: "Art is not what you see, but what you make others see.",
        }
    ];
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        arrows: false,
    };
    return (
        <>
            <div>
            
                <Container className="p-relative sliderWidth" style={{zIndex:"100000",marginTop:"100px"}}>
                    <Slider {...settings}>
                        {quote.map((ele) => {
                            return (
                                <Row className="d-flex align-items-center">
                                    <Col data-aos="fade-up" data-aos-duration="3000" className='p-3' lg={6}>
                                        <div>
                                            <img className='imgclass' src={colanimg} />
                                        </div>
                                        <div className='everychild p-lg-5 text-white' lg={6}>
                                            {ele.text}
                                            <p className='pablopicasso mt-3 authorclass'>-    {ele.author}</p>
                                        </div>
                                        <div>

                                            <div className='text-end' style={{float:"right"}}>
                                                <img className='imgclass float-right' src={colanimg} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col data-aos="fade-up" data-aos-duration="3000" lg={6} className='p-lg-5 z_index1class orderclass'>
                                        <div className='cardclass'>
                                            <div>
                                                <img className='CardImg' src={ele.authorImg} />
                                            </div>
                                            <div className='cardoncontant'>
                                                <span className="authorName">{ele.authorName}</span>
                                                <br></br>
                                                <span className="authorText">{ele.authorText}</span>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            )
                        }
                        )}
                    </Slider>
                </Container>
            </div>
        </>
    )
}

export default Slidersecond
