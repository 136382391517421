import React from "react";
import "./Levelup.css";
import { Col, Container, Row } from "react-bootstrap";
import cardmen from "../../../IMG/men.png";
import medal from "../../../IMG/medal.png";
import coins from "../../../IMG/coins.png";
import music from "../../../IMG/music-dynamic-color.png";
import rupeeColor from "../../../IMG/rupee-dynamic-color.png";
import magic from "../../../IMG/magic.png";
import mic from "../../../IMG/mic.png";
import camera from "../../../IMG/camera.png";
import EllipseRight from "../../../IMG/EllipseRight.png";
const Levelup = ({ fromPage }) => {
  return (
    <>
      <div className={`p-relative ${fromPage===`innovative` ? `mt-5` : `levelupMainDiv`}`}>
        <Container>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              className={`${fromPage===`innovative`? `innovative_levelup`:`Levelup`} text-white mt-4 upperIndex`}
            >
              <p style={{ marginBottom: `${fromPage === 'innovative' ? "7px" : "10px" }`  }}>Level Up Your Talent</p>
              <p className={`${fromPage===`innovative`? `innovative_unlock`:'unlock'}`}>Unlock Rewards & Achievements</p>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              lg={4}
              className={`${fromPage==="innovative"?`mt-4`:`mt-5`} upperIndex`}
              md={6}
            >
              <div className="cardLevelup">
                <p className="cardTitle ">Showcase Creativity</p>
                <h3 className="cardSubTitle text-white">
                  Exhibit your talent to the world
                </h3>
                <div style={{ textAlign: "center", position: "relative" }}>
                  <img className="cardmen" src={cardmen} />
                  <div style={{ position: "absolute", width: "100%", top: 10 }}>
                    <div
                      className="fisrtImgSection"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <img id="music" src={music} />
                      <img id="rupeeColor" src={rupeeColor} />
                    </div>
                    <div
                      style={{ position: "absolute", width: "100%", top: 192 }}
                    >
                      <div
                        className="fisrtImgSection"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <img id="medal" src={medal} />
                        <img id="magic" src={magic} />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ position: "absolute", width: "100%", top: 105 }}
                  >
                    <div
                      className="thirdImgSection"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <img id="camera" src={camera} height={69} width={69} />
                      <img id="mic" src={mic} height={58} width={58} />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              lg={4}
              className={`${fromPage==="innovative"?`mt-4`:`mt-5`} upperIndex`}
              md={6}
            >
              <div className="cardLevelup">
                <p className="cardTitle">Level Up</p>
                <h3 className="cardSubTitle text-white">
                  Unlock new perks & features as you progress
                </h3>
                <div className="middleCardLoremWrapper">
                  <div className="d-flex">
                    <img className="medalimg" src={medal} />
                    <img className="medalimg" src={medal} />
                    <img className="medalimg" src={medal} />
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-start mx-3">
                    <p className="middleCardLoremTitle">Level-up Benefits</p>
                    <div className="middleCardLoremDesc text-white">
                      <h3>+ Build Streaks</h3>
                      <h3>+ Complete Challenges</h3>
                      <h3>+ Unlock new perks</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              lg={4}
              className={`${fromPage==="innovative"?`mt-4`:`mt-5`} upperIndex`}
              md={6}
            >
              <div className="cardLevelup">
                <p className="cardTitle">Reward System</p>
                <h3 className="cardSubTitle text-white">
                  Earn coins and redeem for exclusive rewards
                </h3>
                <div style={{ textAlign: "center" }}>
                  <img src={coins} className="thirdCoinCardImg" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {fromPage === "innovative" ? null : (
          <Row>
            <Col md={12} lg={5}>
              <div className="tp-feature__bottom-shapeshape_levelup imgbgblur">
                <img
                  className=""
                  src={EllipseRight}
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default Levelup;
